import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Champion from "../components/champion"

const h2 = css`
  margin: 1rem 0;
`
const Champions = styled.div`
  margin: 1.5rem 0;
`

const ChampionsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allOwnBreedingsChampionsCsv {
        edges {
          node {
            registryId
            description
          }
        }
      }
      allOwnChampionsCsv {
        edges {
          node {
            registryId
            description
          }
        }
      }
    }
  `)
  const ownBreedingsChampions = data.allOwnBreedingsChampionsCsv.edges.map(
    edge => (
      <Champion
        key={edge.node.id}
        registryId={edge.node.registryId}
        description={edge.node.description}
      />
    )
  )
  const ownChampions = data.allOwnChampionsCsv.edges.map(edge => (
    <Champion
      key={edge.node.id}
      registryId={edge.node.registryId}
      description={edge.node.description}
    />
  ))
  return (
    <Layout>
      <SEO title="Champions" />
      <h1>Champions</h1>
      <h2 css={h2}>Willy Lee FIN and FI Champions - own breedings</h2>
      {ownBreedingsChampions}
      <Champions>
        <h2 css={h2}>
          Champions owned and handled to championship by Willy Lee, Irene
        </h2>
        {ownChampions}
      </Champions>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default ChampionsPage
